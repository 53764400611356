import { localOrUtcTimestampToLocalDate } from "@sembark-travel/datetime-utils"
import { Stack, Text, Alert } from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useState } from "react"
import { generatePath } from "../router-utils"
import { TripDetailsForm } from "./NewItem"
import { ITrip } from "./store"
import { transformAddressToEditableValue } from "../Addresses"

function XHR(xhr: XHRInstance) {
	return {
		async get(id: number | string): Promise<ITrip> {
			return xhr.get(`/trips/${id}`).then((resp) => resp.data.data)
		},
		async update(id: number | string, data: unknown): Promise<ITrip> {
			return xhr.patch(`/trips/${id}`, data).then((resp) => resp.data.data)
		},
	}
}

export function UpdateTrip({
	onSuccess,
	onCancel,
	trip,
}: {
	onSuccess: (trip: ITrip) => void
	onCancel?: () => void
	trip: ITrip
}) {
	const xhr = useXHR()
	const [initialValues] = useState(() => {
		return {
			reference_id: trip.reference_id || "",
			start_date: localOrUtcTimestampToLocalDate(
				trip.start_date_local,
				trip.start_date
			),
			no_of_days: trip.days,
			destinations: trip.destinations,
			no_of_adults: trip.no_of_adults,
			children: trip.children,
			trip_source: trip.trip_source,
			trip_source_contact: trip.trip_source_contact,
			tourist: trip.tourist
				? {
						name: trip.tourist.name,
						email: trip.tourist.email || "",
						phone_numbers: trip.tourist.phone_numbers || [],
						country: undefined,
						address: transformAddressToEditableValue(trip.tourist.address),
					}
				: undefined,
		}
	})
	const readOnlyPackageDetails = Boolean(trip.converted_at || trip.on_hold_at)
	return (
		<Stack gap="6">
			{readOnlyPackageDetails ? (
				<Alert
					title={`You are editing a ${
						trip.converted_at ? "Converted" : "On-Hold"
					} Trip`}
					status="warning"
				>
					<Stack gap="2">
						<Text>
							Some details (e.g. start date, duration and pax) can not be
							modified.
						</Text>
						<Text>
							To update dates/pax, please{" "}
							<Link
								to={generatePath("/trips/:tripId", {
									tripId: trip.id.toString(),
								})}
								textDecoration="underline"
								color="accent"
							>
								update the latest quote
							</Link>
							.
						</Text>
					</Stack>
				</Alert>
			) : null}
			<TripDetailsForm
				onCancel={onCancel}
				showDuplicateExceptId={trip.id}
				initialValues={initialValues}
				readOnlyDates={readOnlyPackageDetails}
				readOnlyPax={readOnlyPackageDetails}
				hideComments
				onSubmit={async (data) => {
					const resp = await XHR(xhr).update(trip.id, data)
					onSuccess(resp)
				}}
			/>
		</Stack>
	)
}
