import {
	Box,
	Inline,
	Stack,
	Text,
	Button,
	Icons,
	RelativeTime,
	Component,
	joinAttributes,
	Time,
} from "@sembark-travel/ui/base"
import {
	CursorListView,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { IActivityLog, activityLogsXHR } from "./store"

type TFilters = TSearchParams & {
	subject_type?: string
	subject_id?: number
	causer_id?: number
	causer_type?: string
	log_names?: Array<string>
}

export function ActivityLogs({
	subjectId,
	subjectType,
	causerId,
	causerType,
	causerIsAuthUser,
	securityLogs,
}: {
	subjectId?: number
	subjectType?: "trips" | "instalments" | "hotel_bookings"
	causerId?: number
	causerType?: "users"
	causerIsAuthUser?: boolean
	securityLogs?: boolean
}) {
	const [params, setParams] = useSearch<TFilters>({
		subject_type: subjectType,
		subject_id: subjectId,
		causer_id: causerId,
		causer_type: causerType,
		log_names: securityLogs ? ["auth", "password"] : undefined,
	})
	return (
		<CursorListView<IActivityLog, TFilters>
			pageKey={`${subjectType}-${subjectId}-${causerType}-${causerId}-${params.log_names?.join("-")}-activity-logs`}
			params={params}
			onCursorChange={(cursor) => setParams({ ...params, cursor })}
			fetch={(xhr, params) => activityLogsXHR(xhr).get(params)}
			removeContainerPadding
			previousPageLabel="Newer"
			nextPageLabel="Older"
			emptyState={({ onRefresh }) => (
				<Text>
					No activities yet!{" "}
					<Button onClick={() => onRefresh()} inline>
						<Icons.Refresh />
					</Button>
				</Text>
			)}
		>
			{({ items: logs }) => (
				<Stack as="ol" listStyleType="disc" paddingLeft="4" gap="1">
					{logs.map((activity) => (
						<Box as="li" key={activity.id} paddingY="1">
							<Component initialState={false}>
								{({ state, setState }) => (
									<Stack gap="1">
										<Inline flexWrap="wrap" gap="1">
											{activity.causer ? (
												<Box display="inline" fontWeight="semibold">
													{causerIsAuthUser ? "You" : activity.causer.name}
												</Box>
											) : null}
											<Text>{activity.description}</Text>
											<Inline gap="1" alignItems="center">
												<Text color="muted">
													(<RelativeTime timestamp={activity.created_at} />)
												</Text>
												<Button inline onClick={() => setState(!state)}>
													<Box as="span" paddingX="1">
														<Icons.DotsVertical size="3" />
													</Box>
												</Button>
											</Inline>
										</Inline>
										{state ? (
											<Box paddingX="2" paddingY="1" borderLeftWidth="2">
												<table>
													<tbody>
														<tr>
															<th>Timestamp</th>
															<Box as="td" paddingX="2">
																:
															</Box>
															<td>
																<Time
																	timestamp={activity.created_at}
																	dateFormat
																	timeFormat
																/>
															</td>
														</tr>
														<tr>
															<th>IP Address</th>
															<Box as="td" paddingX="2">
																:
															</Box>
															<td>{activity.ip_address || "-"}</td>
														</tr>
														<tr>
															<th>Platform</th>
															<Box as="td" paddingX="2">
																:
															</Box>
															<td>{activity.user_agent?.platform || "-"}</td>
														</tr>
														<tr>
															<th>Client</th>
															<Box as="td" paddingX="2">
																:
															</Box>
															<td>
																{activity.user_agent
																	? joinAttributes(
																			activity.user_agent.browser,
																			activity.user_agent.browser_version
																		)
																	: "-"}
															</td>
														</tr>
														<tr>
															<th>Geo</th>
															<Box as="td" paddingX="2">
																:
															</Box>
															<td>
																{activity.properties?.geo
																	? joinAttributes(
																			[
																				activity.properties.geo.city,
																				activity.properties.geo.regionName,
																				activity.properties.geo.country,
																				activity.properties.geo.postal,
																			].join(", "),
																			activity.properties.geo.timezone
																		)
																	: "-"}
															</td>
														</tr>
													</tbody>
												</table>
											</Box>
										) : null}
									</Stack>
								)}
							</Component>
						</Box>
					))}
				</Stack>
			)}
		</CursorListView>
	)
}
