import {
	Box,
	Button,
	Inline,
	Stack,
	Text,
	Icons,
	Container,
	Spinner,
	Table,
	TableDataCell,
	joinAttributes,
	Badge,
	Grid,
	Col,
	Select,
	Heading,
	Tabs,
	TabsList,
	TabItem,
	TabContent,
	Dropdown,
	Alert,
	DateTimeInput,
} from "@sembark-travel/ui/base"
import {
	useSearch,
	Search,
	areAdvancedFiltersAppliedDefault,
} from "@sembark-travel/ui/list"
import {
	Breadcrumbs,
	useGetBackUrlFromLocation,
	useLocationQuery,
	queryToSearch,
	ButtonLink,
	XHRLink,
	Link,
} from "@sembark-travel/ui/router"
import {
	parseDateFromQuery,
	formatDate,
	dateToQuery,
	subtractUnit,
	addUnit,
	getDiff,
	utcTimestampToLocalDate,
	startOf,
	dateToUTCString,
	endOf,
	localOrUtcTimestampToLocalDate,
	getDiffBetweenLocalAndUtcTimestamp,
} from "@sembark-travel/datetime-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useEffect, useMemo } from "react"
import useSWR from "swr"
import { $PropertyType, $Values } from "utility-types"
import { ICabType, SelectCabTypes } from "../CabTypes"
import config from "../config"
import { generatePath, useNavigate } from "../router-utils"
import { TTripOperationalBooking } from "./store"
import { ICabSchedule, IScheduledCab } from "../CabScheduling"
import { DayBookingServicesOverview } from "./Item"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import { Email, PhoneNumber } from "../Contacts"
import { ShareTripOperationalBookings } from "./ShareOperationalBookings"
import { TripOperationalBookingsItemForDateInDialog } from "./ForDateItem"
import { FeatureFlag } from "../Auth"
import {
	BOOKING_STATUS,
	IOperationalBookingFilters,
	IOperationalBookingFiltersInLocationQuery,
	filtersToRequestParams,
	operationBookingLocationQueryToParams,
	operationalBookingFilterParamsToLocationQuery,
} from "./utils"
import { SelectTripDestination } from "../TripDestinations"
import { GetFieldValue, SelectField } from "@sembark-travel/ui/form"
import { SelectTransporServiceProviders } from "../TransportServiceProviders"
import {
	SelectTransportServiceLocationPoints,
	TTransportServiceLocationPoint,
} from "../TransportServices"
import {
	SelectActivity,
	TTravelActivity,
	TTravelActivityTicketTouristConfiguration,
	TTravelActivityTicketType,
} from "../TravelActivities"
import { SelectUsers } from "../Users"
import { TTravelActiviytBooking } from "../TravelActivityBookings"
import { TemporaryStorage } from "../TemporaryStorage"

function XHR(xhr: XHRInstance) {
	return {
		async getSchedules(
			params: Record<string, unknown>
		): Promise<Array<TTripOperationalBooking>> {
			return xhr
				.get("/trip-operational-bookings", {
					params: {
						...params,
						pagination: "all",
					},
				})
				.then((resp) => resp.data.data)
		},
	}
}

export function TripOperationalBookingsForDate({ date }: { date: string }) {
	const xhr = useXHR()
	const parsedDate = useMemo(() => {
		return parseDateFromQuery(date)
	}, [date])
	const [queryFilters, setQueryFilters] = useLocationQuery<
		IOperationalBookingFilters,
		IOperationalBookingFiltersInLocationQuery
	>({
		toQuery: operationalBookingFilterParamsToLocationQuery,
		fromQuery: operationBookingLocationQueryToParams,
	})
	const [params, setParams] = useSearch<IOperationalBookingFilters>({
		q: "",
		...queryFilters,
		date: queryFilters.date || new Date(),
	})
	useEffect(() => {
		setQueryFilters(params)
	}, [params, setQueryFilters])
	const filters = useMemo(() => {
		return {
			...filtersToRequestParams({
				...params,
				start_date: parsedDate,
				end_date: parsedDate,
			}),
			include: ["due_payments_count", "quote_booking_diff"].join(","),
		}
	}, [params, parsedDate])
	const {
		data,
		mutate: revalidate,
		isValidating,
	} = useSWR(
		`trip_operational_bookings_for_date_${date}${queryToSearch(filters)}`,
		() => {
			return XHR(xhr).getSchedules(filters)
		}
	)
	const cabSchedulesOverview = useMemo(() => {
		return getCabSchedulesOverview(
			data ? data.flatMap((b) => b.cab_schedules || []) : []
		)
	}, [data])
	const travelActivityBookingsOverview = useMemo(() => {
		return getTravelActivityBookingsOverview(
			data ? data.flatMap((b) => b.travel_activity_bookings || []) : []
		)
	}, [data])
	const back = useGetBackUrlFromLocation()
	const navigate = useNavigate()
	return (
		<>
			<Box position="relative" zIndex="10">
				<Breadcrumbs
					title="Operational Bookings"
					items={[
						[
							generatePath("/operational-bookings/calendar") +
								queryToSearch({ date: dateToQuery(parsedDate) }),
							"Calendar",
						],
						[
							"",
							parsedDate
								? formatDate(parsedDate, config.dateDisplayFormat)
								: "Date",
						],
					]}
					actions={
						<Inline gap="2" flexWrap={"wrap"}>
							{data?.length ? (
								<>
									<ShareTripOperationalBookings
										trips={data}
										canShareWith={[
											"guest",
											"transportServiceProvider",
											"driver",
										]}
									>
										{({ share }) => (
											<Button level="primary" onClick={() => share()} size="sm">
												<Icons.Share /> Share
											</Button>
										)}
									</ShareTripOperationalBookings>
									{parsedDate ? (
										<TemporaryStorage
											initUrl={"/trip-operational-bookings/download"}
											initParams={{
												...filters,
												timezone_offset: config.timezoneOffset,
											}}
										>
											{({ message, generate, url, reset, isGenerating }) =>
												url ? (
													<Button
														as="a"
														href={url}
														target="_blank"
														download
														level="primary"
														size="sm"
														onClick={() => reset()}
													>
														{message}
													</Button>
												) : (
													<Button
														size="sm"
														onClick={generate}
														disabled={isGenerating}
													>
														{message || (
															<>
																<Icons.DocumentDownload /> Download
															</>
														)}
													</Button>
												)
											}
										</TemporaryStorage>
									) : null}
								</>
							) : null}
							{parsedDate ? (
								<Dropdown alignRight>
									<Dropdown.ToggleButton size="sm">
										<Icons.DotsVertical />
									</Dropdown.ToggleButton>
									<Dropdown.Menu>
										<TemporaryStorage
											initUrl={"/trip-operational-bookings/download"}
											initParams={{
												...filters,
												start_date: dateToUTCString(
													startOf(parsedDate, "month")
												),
												end_date: dateToUTCString(endOf(parsedDate, "month")),
												timezone_offset: config.timezoneOffset,
											}}
										>
											{({ generate, url, message, reset }) => (
												<Dropdown.MenuItem
													onClick={!url ? generate : () => reset()}
													href={url || "#"}
													target={url ? "_blank" : undefined}
												>
													{message || (
														<>
															<Icons.DocumentDownload /> Monthly Download (
															{formatDate(parsedDate, "MMM'YY")})
														</>
													)}
												</Dropdown.MenuItem>
											)}
										</TemporaryStorage>

										{data?.length ? (
											<FeatureFlag flag="travel_activities">
												<Dropdown.MenuItemDivider />
												<Dropdown.MenuItem
													as={XHRLink}
													query={{
														...filters,
														timezone_offset: config.timezoneOffset,
													}}
													href="/trip-cab-schedules/download"
												>
													<Icons.Taxi /> Download Only Cabs
												</Dropdown.MenuItem>
												<Dropdown.MenuItem
													as={XHRLink}
													query={{
														...filters,
														timezone_offset: config.timezoneOffset,
													}}
													href="/travel-activity-bookings/download"
												>
													<Icons.Ticket /> Download Only Activities
												</Dropdown.MenuItem>
											</FeatureFlag>
										) : null}
									</Dropdown.Menu>
								</Dropdown>
							) : null}
						</Inline>
					}
				/>
			</Box>
			<Search
				title={formatDate(parsedDate, config.dateDisplayFormat)}
				initialParams={params}
				placeholder="Search by id, destination, guest..."
				Filters={OperationalBookingFilters}
				onSearch={setParams}
				resetParams={(params) => ({
					q: "",
					date: params.date,
				})}
				areAdvancedFiltersApplied={(params) => {
					const { date, ...otherParams } = params
					return areAdvancedFiltersAppliedDefault(otherParams)
				}}
				actions={
					<>
						<Inline gap="2" alignItems="center">
							<ButtonLink
								title="Previous Day"
								to={
									generatePath("/operational-bookings/date/:date", {
										date: dateToQuery(subtractUnit(parsedDate, 1, "day")),
									}) + queryToSearch({ back })
								}
							>
								<Icons.ChevronDown rotate="90" />
							</ButtonLink>
							<DateTimeInput
								rightAlign
								value={parsedDate}
								renderMenu={({ toggle }) => (
									<Button onClick={toggle}>
										{formatDate(parsedDate, "DD MMM")}
									</Button>
								)}
								onChange={(date) => {
									navigate("/operational-bookings/date/:date", {
										params: {
											date: dateToQuery(date || new Date()),
										},
									})
								}}
							/>
							<ButtonLink
								title="Next Day"
								to={
									generatePath("/operational-bookings/date/:date", {
										date: dateToQuery(addUnit(parsedDate, 1, "day")),
									}) + queryToSearch({ back })
								}
							>
								<Icons.ChevronDown rotate="270" />
							</ButtonLink>
						</Inline>
					</>
				}
			>
				{({ setSearchParams, searchParams }) => (
					<Container paddingY="6" cursor={isValidating ? "wait" : undefined}>
						{!data ? (
							<Spinner padding="4" alignCenter />
						) : data.length ? (
							<Stack gap="8">
								{cabSchedulesOverview.cabsCount ||
								travelActivityBookingsOverview.quantity ? (
									<Stack gap="4">
										<Heading as="h5" fontSize="lg">
											Bookings Overview
										</Heading>
										<Tabs
											defaultActive={
												cabSchedulesOverview.cabsCount ||
												!travelActivityBookingsOverview.quantity
													? "cab_schedules"
													: "travel_activity_bookings"
											}
											gap="4"
										>
											<FeatureFlag flag="travel_activities">
												<TabsList>
													<TabItem id="cab_schedules">Cab Schedule</TabItem>
													<TabItem id="travel_activity_bookings">
														Activity Bookings
													</TabItem>
												</TabsList>
											</FeatureFlag>
											<TabContent id="cab_schedules">
												<Box overflow="auto">
													{cabSchedulesOverview.cabsCount ? (
														<Table
															bordered
															hover
															responsive
															whiteSpace="preserveAndWrap"
															stickyHeader
															stickyFooter
															stickyFirstColumn
															stickyLastColumn
															style={{ maxHeight: "400px", minWidth: "400px" }}
															alignCols={{
																[cabSchedulesOverview.cabsPerPickup.length + 1]:
																	"right",
															}}
															headers={["" as React.ReactNode]
																.concat(
																	cabSchedulesOverview.cabsPerPickup.map(
																		(c) => (
																			<Button
																				key={c.pickup.id}
																				inline
																				title="Click to Apply Filter"
																				onClick={() => {
																					setSearchParams({
																						...searchParams,
																						transport_service_location_points: [
																							c.pickup,
																						],
																					})
																				}}
																			>
																				<Text as="span" color="default">
																					{c.pickup.name}
																				</Text>
																			</Button>
																		)
																	)
																)
																.concat(["Total"])}
															rows={cabSchedulesOverview.cabTypes.map((c) => {
																const cabsForThisType =
																	cabSchedulesOverview.cabsPerPickup.reduce<
																		Array<IScheduledCab>
																	>(
																		(cabsForThisType, { cabs }) =>
																			cabsForThisType.concat(
																				cabs.filter(
																					(cab) => cab.cab_type.id === c.id
																				)
																			),
																		[]
																	)
																return [
																	(
																		<Button
																			inline
																			title="Click to Apply Filter"
																			onClick={() => {
																				setSearchParams({
																					...searchParams,
																					cab_types: [c],
																				})
																			}}
																		>
																			<Text
																				as="span"
																				color="default"
																				maxWidth={"xs"}
																				textAlign={"left"}
																				whiteSpace="normal"
																				style={{ minWidth: "150px" }}
																			>
																				{c.name}
																			</Text>
																		</Button>
																	) as React.ReactNode,
																]
																	.concat(
																		cabSchedulesOverview.cabsPerPickup.map(
																			({ cabs }) => {
																				const cabsForThisTypeForThisPickup =
																					cabs.filter(
																						(cab) => cab.cab_type.id === c.id
																					)
																				if (
																					cabsForThisTypeForThisPickup.length ===
																					0
																				)
																					return null
																				return (
																					<Box>
																						<Box color="success" as="span">
																							{
																								cabsForThisTypeForThisPickup.filter(
																									(c) => c.booked
																								).length
																							}
																						</Box>
																						{" + "}
																						<Box color="warning" as="span">
																							{
																								cabsForThisTypeForThisPickup.filter(
																									(c) => !c.booked
																								).length
																							}
																						</Box>
																					</Box>
																				)
																			}
																		)
																	)
																	.concat([
																		<Box>
																			<Box color="success" as="span">
																				{
																					cabsForThisType.filter(
																						(c) => c.booked
																					).length
																				}
																			</Box>
																			{" + "}
																			<Box color="warning" as="span">
																				{
																					cabsForThisType.filter(
																						(c) => !c.booked
																					).length
																				}
																			</Box>
																			{" = "}
																			<Box as="span">
																				{cabsForThisType.length}
																			</Box>
																		</Box>,
																	])
															})}
														>
															<tfoot>
																<tr>
																	<TableDataCell textAlign="right">
																		Total
																	</TableDataCell>
																	{cabSchedulesOverview.cabsPerPickup.map(
																		({ pickup, cabs }) => (
																			<TableDataCell key={pickup.id}>
																				<Box color="success" as="span">
																					{cabs.filter((c) => c.booked).length}
																				</Box>
																				{" + "}
																				<Box color="warning" as="span">
																					{cabs.filter((c) => !c.booked).length}
																				</Box>
																				{" = "}
																				<Box as="span">{cabs.length}</Box>
																			</TableDataCell>
																		)
																	)}
																	<TableDataCell textAlign={"right"}>
																		<Box color="success" as="span">
																			{cabSchedulesOverview.bookedCabsCount}
																		</Box>
																		{" + "}
																		<Box color="warning" as="span">
																			{cabSchedulesOverview.notBookedCabsCount}
																		</Box>
																		{" = "}
																		<Box as="span">
																			{cabSchedulesOverview.cabsCount}
																		</Box>
																	</TableDataCell>
																</tr>
															</tfoot>
														</Table>
													) : (
														<Box
															padding="4"
															bgColor={"default"}
															borderWidth={"1"}
															rounded="md"
														>
															<Text color="muted" textAlign={"center"}>
																No cab schedules
															</Text>
														</Box>
													)}
												</Box>
											</TabContent>
											<TabContent id="travel_activity_bookings">
												<Box overflow="auto">
													{travelActivityBookingsOverview.quantity ? (
														<Table
															bordered
															hover
															responsive
															whiteSpace="preserveAndWrap"
															stickyHeader
															stickyFooter
															stickyFirstColumn
															stickyLastColumn
															style={{ maxHeight: "400px", minWidth: "400px" }}
															alignCols={{
																[travelActivityBookingsOverview.ticketTypes
																	.length + 1]: "right",
															}}
															headers={[""]
																.concat(
																	travelActivityBookingsOverview.ticketTypes.map(
																		(c) => c.configuration.name
																	)
																)
																.concat(["Total"])}
															rows={travelActivityBookingsOverview.activitiesWithTickets.map(
																(c) => {
																	return [
																		(
																			<Button
																				inline
																				title="Click to Apply Filter"
																				onClick={() => {
																					setSearchParams({
																						...searchParams,
																						travel_activities: [c.activity],
																					})
																				}}
																			>
																				<Text
																					maxWidth={"xs"}
																					as="span"
																					color="default"
																					textAlign="left"
																					whiteSpace="normal"
																					style={{ minWidth: "150px" }}
																				>
																					{c.activity.name}
																					{c.ticketType
																						? ` - ${c.ticketType.name}`
																						: ``}
																				</Text>
																			</Button>
																		) as React.ReactNode,
																	]
																		.concat(
																			travelActivityBookingsOverview.ticketTypes.map(
																				({ configuration }) => {
																					const ticketForThisConfiguration =
																						c.tickets[configuration.id]
																					if (!ticketForThisConfiguration)
																						return null
																					return (
																						<Box>
																							<Box color="success" as="span">
																								{
																									ticketForThisConfiguration.booked_quantity
																								}
																							</Box>
																							{" + "}
																							<Box color="warning" as="span">
																								{ticketForThisConfiguration.quantity -
																									ticketForThisConfiguration.booked_quantity}
																							</Box>
																						</Box>
																					)
																				}
																			)
																		)
																		.concat([
																			<Box>
																				<Box color="success" as="span">
																					{c.booked_quantity}
																				</Box>
																				{" + "}
																				<Box color="warning" as="span">
																					{c.quantity - c.booked_quantity}
																				</Box>
																			</Box>,
																		])
																}
															)}
														>
															<tfoot>
																<tr>
																	<TableDataCell textAlign="right">
																		Total
																	</TableDataCell>
																	{travelActivityBookingsOverview.ticketTypes.map(
																		({
																			configuration,
																			quantity,
																			booked_quantity,
																		}) => (
																			<TableDataCell key={configuration.id}>
																				<Box color="success" as="span">
																					{booked_quantity}
																				</Box>
																				{" + "}
																				<Box color="warning" as="span">
																					{quantity - booked_quantity}
																				</Box>
																				{" = "}
																				<Box as="span">{quantity}</Box>
																			</TableDataCell>
																		)
																	)}
																	<TableDataCell textAlign={"right"}>
																		<Box color="success" as="span">
																			{
																				travelActivityBookingsOverview.booked_quantity
																			}
																		</Box>
																		{" + "}
																		<Box color="warning" as="span">
																			{travelActivityBookingsOverview.quantity -
																				travelActivityBookingsOverview.booked_quantity}
																		</Box>
																		{" = "}
																		<Box as="span">
																			{travelActivityBookingsOverview.quantity}
																		</Box>
																	</TableDataCell>
																</tr>
															</tfoot>
														</Table>
													) : (
														<Box
															padding="4"
															bgColor={"default"}
															borderWidth={"1"}
															rounded="md"
														>
															<Text color="muted" textAlign={"center"}>
																No activity bookings
															</Text>
														</Box>
													)}
												</Box>
											</TabContent>
										</Tabs>
									</Stack>
								) : null}

								<Stack gap="8">
									{data.map((trip) => {
										const selectedDay =
											getDiff(
												date,
												localOrUtcTimestampToLocalDate(
													trip.start_date_local,
													trip.start_date
												),
												"day"
											) + 1

										const timezoneDiff = getDiffBetweenLocalAndUtcTimestamp(
											trip.start_date_local,
											trip.start_date
										)
										return (
											<Grid key={trip.id} gap="4">
												<Col sm={12} md={4} lg={3}>
													<Stack gap="1">
														<Inline gap="2">
															<Stack gap="1">
																<Inline
																	flexWrap="wrap"
																	gap="2"
																	alignItems="center"
																>
																	{trip.tourist ? (
																		<Text fontWeight="semibold" fontSize="md">
																			{trip.tourist.name}
																		</Text>
																	) : (
																		<Text color="warning" fontSize="sm">
																			<Icons.Attention /> Guest details missing!
																		</Text>
																	)}
																	<Box color="muted" fontSize="sm">
																		{joinAttributes(
																			trip.tourist?.phone_numbers?.length ? (
																				<PhoneNumber
																					value={trip.contact.phone_numbers}
																					iconOnly
																				/>
																			) : null,
																			trip.tourist?.email ? (
																				<Email
																					value={trip.contact.email}
																					iconOnly
																				/>
																			) : null,
																			`${trip.no_of_adults}A${
																				trip.children.length
																					? `, ${trip.children.length}C`
																					: ``
																			}`
																		)}
																	</Box>
																</Inline>
																<Box color="muted">
																	{joinAttributes(
																		<Link
																			to={generatePath(
																				"/trips/:tripId/services-bookings/:serviceType",
																				{
																					tripId: trip.id.toString(),
																					serviceType: "operational",
																				}
																			)}
																			color="accent"
																		>
																			Trip {trip.id}
																		</Link>,
																		trip.trip_source.short_name,
																		trip.trip_source_contact ? (
																			<PhoneNumber
																				value={
																					trip.trip_source_contact.phone_numbers
																				}
																				iconOnly
																			/>
																		) : null
																	)}
																</Box>
															</Stack>
														</Inline>
														<Box>
															{joinAttributes(
																<TripOperationalBookingsItemForDateInDialog
																	tripId={trip.id}
																	tripStartDateUtc={trip.start_date}
																	day={selectedDay}
																	date={utcTimestampToLocalDate(date)}
																	onRefresh={() => revalidate()}
																	showQuoteBookingDiffWarning
																>
																	{({ show }) => (
																		<Button size="sm" onClick={() => show()}>
																			{withOrdinalSuffix(selectedDay)} Day{" "}
																			<Icons.ArrowExpand />
																		</Button>
																	)}
																</TripOperationalBookingsItemForDateInDialog>,
																trip.due_payments_count === undefined ||
																	trip.due_payments_count ===
																		null ? null : trip.due_payments_count >
																  0 ? (
																	<Badge danger>
																		<Icons.BankNotes /> Due
																	</Badge>
																) : (
																	<Badge success title="Payments Received">
																		<Icons.BankNotes /> Cleared
																	</Badge>
																)
															)}
														</Box>
														<Inline gap="1" alignItems="center">
															<Box color="muted">
																<Icons.UserGroup />
															</Box>
															{trip.operations_team?.length ? (
																<Text fontSize="sm" textOverflow="truncate">
																	{trip.operations_team
																		.map((t, _, arr) =>
																			arr.length > 1
																				? t.name.slice(0, 4)
																				: t.name
																		)
																		.join(", ")}
																</Text>
															) : (
																<Text fontSize="xs">N/A</Text>
															)}
														</Inline>
														{trip.quote_booking_diff?.hotels_changed ||
														trip.quote_booking_diff?.cabs_changed ? (
															<Inline>
																<Link
																	to={generatePath(
																		"/trips/:tripId/services-bookings/:serviceType",
																		{
																			tripId: trip.id.toString(),
																			serviceType: "operational",
																		}
																	)}
																	color="accent"
																>
																	<Alert inline status="warning">
																		Latest Quote and Booking Mismatch
																	</Alert>
																</Link>
															</Inline>
														) : null}
														{timezoneDiff ? (
															<Alert
																status="warning"
																title={`Trip details in Different Timezone by ${timezoneDiff} mins`}
															>
																Some service details might be invalid.
															</Alert>
														) : null}
													</Stack>
												</Col>
												<Col>
													<DayBookingServicesOverview
														services={trip.daywise_services[0].services}
														onRefresh={() => revalidate()}
														showPrices={true}
													/>
												</Col>
											</Grid>
										)
									})}
								</Stack>
							</Stack>
						) : (
							<Stack paddingY="8">
								<Text fontSize="lg" fontWeight="semibold" textAlign="center">
									No operatable bookings for{" "}
									{formatDate(parsedDate, config.dateDisplayFormat)}
								</Text>
							</Stack>
						)}
					</Container>
				)}
			</Search>
		</>
	)
}

function getCabSchedulesOverview(schedules: Array<ICabSchedule> = []) {
	const cabsPerPickup: {
		[key: string]: {
			pickup: TTransportServiceLocationPoint
			cabs: ICabSchedule["cabs"]
		}
	} = {}
	const cabTypes: Array<ICabType> = []
	let cabsCount = 0
	let bookedCabsCount = 0
	for (const schedule of schedules) {
		for (const scheduledCab of schedule.cabs) {
			const cabType = scheduledCab.cab_type
			if (cabTypes.findIndex((c) => cabType.id === c.id) === -1) {
				cabTypes.push(cabType)
			}
		}
		cabsCount += schedule.cabs.length
		bookedCabsCount += schedule.booked_cabs_count
		const pickup = schedule.transport_service.locations?.from
		if (!pickup) continue
		const pickupName = pickup.name
		if (!cabsPerPickup[pickupName]) {
			cabsPerPickup[pickupName] = {
				pickup,
				cabs: [],
			}
		}
		cabsPerPickup[pickupName].cabs = cabsPerPickup[pickupName].cabs.concat(
			schedule.cabs
		)
	}
	const cabsCountWithPickupLocations: Array<{
		pickup: TTransportServiceLocationPoint
		cabs: Array<$PropertyType<ICabSchedule, "cabs">[0]>
	}> = Object.keys(cabsPerPickup).map((pickupName) => ({
		pickup: cabsPerPickup[pickupName].pickup,
		cabs: cabsPerPickup[pickupName].cabs,
	}))
	cabsCountWithPickupLocations.sort(function (a, b) {
		return a.cabs.length > b.cabs.length ? -1 : 1
	})
	return {
		cabsCount,
		bookedCabsCount,
		notBookedCabsCount: cabsCount - bookedCabsCount,
		cabTypes,
		cabsPerPickup: cabsCountWithPickupLocations,
	}
}

function getTravelActivityBookingsOverview(
	bookings: Array<TTravelActiviytBooking> = []
) {
	const activitiesWithTicketsById: {
		[key: string]: {
			activity: TTravelActivity
			ticketType?: TTravelActivityTicketType
			tickets: {
				[key: string]: {
					configuration: TTravelActivityTicketTouristConfiguration
					quantity: number
					booked_quantity: number
				}
			}
		}
	} = {}
	bookings.forEach((booking) => {
		const activityName = `A_${booking.activity.id}_T${booking.ticket_type?.id || "0"}`
		if (!activitiesWithTicketsById[activityName]) {
			activitiesWithTicketsById[activityName] = {
				activity: booking.activity,
				ticketType: booking.ticket_type,
				tickets: {},
			}
		}
		const tickets = booking.ticket_tourist_configurations.map((t) => ({
			configuration: t.configuration,
			quantity: t.quantity,
			booked_quantity: booking.is_booked ? t.quantity : 0,
		}))
		const existingTickets = activitiesWithTicketsById[activityName].tickets
		tickets.forEach((ticket) => {
			const existingTicket = existingTickets[ticket.configuration.id]
			if (existingTicket) {
				existingTicket.quantity += ticket.quantity
				existingTicket.booked_quantity += ticket.booked_quantity
			} else {
				existingTickets[ticket.configuration.id] = { ...ticket }
			}
		})
	})
	const ticketTypesById = Object.values(activitiesWithTicketsById).reduce<
		$Values<typeof activitiesWithTicketsById>["tickets"]
	>((data, { tickets }) => {
		Object.keys(tickets).forEach((id) => {
			const ticket = tickets[id]
			if (data[id]) {
				data[id].quantity += ticket.quantity
				data[id].booked_quantity += ticket.booked_quantity
			} else {
				data[id] = { ...ticket }
			}
		})
		return data
	}, {})

	const ticketTypes = Object.values(ticketTypesById)
	ticketTypes.sort((a, b) => b.quantity - a.quantity)

	const activitiesWithTickets = Object.values(activitiesWithTicketsById).map(
		(a) => {
			const [quantity, booked_quantity] = Object.values(a.tickets).reduce<
				[number, number]
			>(
				([quantity, booked_quantity], t) => {
					quantity += t.quantity
					booked_quantity += t.booked_quantity
					return [quantity, booked_quantity]
				},
				[0, 0]
			)
			return {
				...a,
				quantity,
				booked_quantity,
			}
		}
	)

	activitiesWithTickets.sort((a, b) => b.quantity - a.quantity)

	const [quantity, booked_quantity] = activitiesWithTickets.reduce<
		[number, number]
	>(
		([quantity, booked_quantity], a) => {
			quantity += a.quantity
			booked_quantity += a.booked_quantity
			return [quantity, booked_quantity]
		},
		[0, 0]
	)

	return {
		ticketTypes,
		activitiesWithTickets,
		quantity,
		booked_quantity,
	}
}

export function OperationalBookingFilters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectTripDestination}
				name="trip_destinations"
				multiple
				label="Trip Destinations"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransporServiceProviders}
				name="transport_service_providers"
				multiple
				label="Service Providers"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransportServiceLocationPoints}
				name="transport_service_location_points"
				label="Service Cities"
				multiple
			/>
			<SelectField
				select={SelectCabTypes}
				name="cab_types"
				label="Cab Types"
				multiple
				fetchOnMount
			/>
			<FeatureFlag flag="travel_activities">
				<SelectField
					select={SelectActivity}
					name="travel_activities"
					label="Travel Activity Name"
					multiple
				/>
				<GetFieldValue<IOperationalBookingFilters["travel_activities"]>
					name={`travel_activities`}
				>
					{({ value: activities }) => {
						if (!activities) return null
						const ticket_types = activities.flatMap((a) => a.ticket_types || [])
						if (!ticket_types.length) return null
						return (
							<Box flex="1" maxWidth="sm">
								<SelectField
									label="Ticket/Package Type"
									select={Select}
									name={"travel_activity_ticket_types"}
									options={ticket_types}
									multiple
								/>
							</Box>
						)
					}}
				</GetFieldValue>
			</FeatureFlag>
			<SelectField
				label="Team"
				select={SelectUsers}
				name="owners"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={Select}
				name="status"
				label="Booking Status"
				options={BOOKING_STATUS}
			/>
		</Stack>
	)
}
