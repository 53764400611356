import type { ICab } from "../Cabs"
import type { ICabType } from "../CabTypes"
import type { TDriver } from "../Drivers"
import type { ILocation } from "../Locations"
import type { IPaymentable } from "../Payments"
import type { ITransportServiceProvider } from "../TransportServiceProviders"
import type { ITrip } from "../Trips"
import type { IUser } from "../Users"
import type { ITransportService } from "./../TransportServices"
import { useXHR, type XHRInstance } from "@sembark-travel/xhr"
import useSWR, { type SWRConfiguration } from "swr"
import { TArrivalDepartureDetail } from "../Trips"
import { type IBooking } from "../HotelBookings"
import { queryToSearch } from "@sembark-travel/ui/router"

export interface ICabScheduleStage {
	id: number
	name: string
	title: string
	is_latest: boolean
	comments?: string
	created_at: string
	created_by: IUser
	is_before_booked: boolean
	is_same_or_after_booked: boolean
}

export interface IScheduledCab extends IPaymentable {
	id: number
	cab_type: ICabType
	cab_locality?: ILocation
	cab?: ICab
	booked: boolean
	driver?: TDriver
	transport_service_provider?: ITransportServiceProvider
	currency: string
	calculated_price?: number
	given_price?: number
	booked_price?: number
	latest_booking_stage: ICabScheduleStage
	cab_schedule?: ICabSchedule
	trip?: ITrip
	confirmation_details?: string
}

export interface ICabSchedule {
	id: number
	start_date: string
	start_date_local?: string
	start_time_utc?: string
	start_time_local?: string
	end_date: string
	end_date_local?: string
	end_time_local?: string
	duration_formatted?: string
	transport_service: ITransportService
	trip_id: number
	trip: ITripCabSchedule
	cabs: IScheduledCab[]
	cab_types: Array<{
		type: ICabType
		count: number
		booked_count: number
		not_booked_count: number
		all_booked: boolean
	}>
	cabs_booked: boolean
	booked_cabs_count: number
	can_modify?: boolean
	can_view_prices?: boolean
	itinerary?: {
		id: number
		name: string
		description?: string
	}
	remarks?: string
}

export interface ITripCabSchedule
	extends Pick<
		ITrip,
		| "id"
		| "children"
		| "destinations"
		| "start_date"
		| "start_date_local"
		| "end_date"
		| "end_date_local"
		| "days"
		| "nights"
		| "trip_source"
		| "no_of_adults"
		| "contact"
		| "tourist"
		| "reference_id"
		| "operations_team"
		| "due_payments_count"
		| "cancellation_reason"
		| "arrival_details"
		| "departure_details"
		| "trip_source_contact"
		| "converted_at"
		| "converted_by"
		| "on_hold_at"
		| "on_hold_by"
		| "tenant_brand"
	> {
	package_price: number
	schedules: Array<ICabSchedule>
	is_shared?: boolean
	can_modify?: boolean
	can_view_prices?: boolean
	total_payments_amount: number
	total_payments_amount_currency: string
}

export type TCabScheduleWithMeta = {
	data: ICabSchedule
	meta: {
		checkin_hotels: Array<IBooking>
		checkout_hotels: Array<IBooking>
		stay_hotels: Array<IBooking>
		arrival_details: Array<TArrivalDepartureDetail> | null
		departure_details: Array<TArrivalDepartureDetail> | null
		itinerary?: {
			name: string
			description?: string
		}
	}
}

export function useScheduleDetails(
	scheduleId: string | number | null,
	params?: Record<string, unknown>,
	config?: SWRConfiguration,
	key?: string
) {
	const xhr = useXHR()
	return useSWR<TCabScheduleWithMeta>(
		scheduleId
			? `/api/cab-schedules/${key || scheduleId}${queryToSearch(params)}`
			: null,
		async () => {
			return xhr
				.get(`/cab-schedules/${scheduleId}`, { params })
				.then((resp) => resp.data)
		},
		config
	)
}

export function schedulesXHR(xhr: XHRInstance) {
	return {
		async getSchedules(params: unknown): Promise<Array<ICabSchedule>> {
			return xhr
				.get("/cab-schedules", { params })
				.then((resp) => resp.data.data)
		},
	}
}
