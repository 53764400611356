import {
	Box,
	Button,
	Inline,
	Icons,
	Select,
	Table,
	TableDataCell,
	Alert,
	Stack,
	Col,
	Component,
	Grid,
	useTimeout,
	Text,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { useXHR, isAbortError } from "@sembark-travel/xhr"
import React, { useMemo, useRef, useEffect, useState } from "react"
import { Required } from "utility-types"
import * as Validator from "yup"
import { SelectTransportServiceLocations } from "../TransportServices/List"
import { IDaywisePrice } from "../utils"
import { SelectCabTypes, ICabType } from "./../CabTypes"
import { ILocation } from "./../Locations"
import {
	ITransportService,
	TTransportServiceLocation,
} from "./../TransportServices"
import {
	formatDate,
	isSame,
	dateToUTCString,
} from "@sembark-travel/datetime-utils"
import {
	EmptyNumberValidator,
	TextInputField,
	SelectField,
	GetFieldValue,
	useFieldValue,
	useForm,
	FieldArray,
	SwitchInputField,
	addKeyToFieldArrayItem,
	getFieldArrayItemKey,
} from "@sembark-travel/ui/form"
import { type TTripDestination } from "../TripDestinations"
import { type TChildrenArray } from "../Tourists"
import { Money } from "@sembark-travel/ui/base"
import {
	formatMoneyByDecimal,
	moneyParseByDecimal,
} from "@sembark-travel/money"
import { InlineSelectTenantCurrencyInputField } from "../Currencies"

export const ValidationSchema = Validator.object().shape({
	transport_service: Validator.object().required("Service is required"),
	transport_service_location: Validator.object().required(
		"Locations are required"
	),
	cabs: Validator.array().of(
		Validator.object().shape({
			cab_type: Validator.object().required("Cab type field is required"),
			cab_locality: Validator.object().nullable(true),
			no_of_cabs: EmptyNumberValidator()
				.positive("Number of cabs should be a positive integer")
				.integer("Number of cabs should be a positive integer.")
				.required("Number of cabs is required"),
		})
	),
})

type TUsedCabTypes = Array<{
	cab_type: ICabType
	no_of_cabs: number
}>

export type TTransportServiceDatewisePricesFieldValue = {
	transport_service?: ITransportService
	transport_service_location?: TTransportServiceLocation
	comments?: string
	cabs?: Array<{
		__id: number
		no_of_cabs: number
		cab_locality?: ILocation
		cab_type?: ICabType
		fetching_prices?: 0 | 1
		date_wise_prices?: Array<
			IDaywisePrice & {
				per_quantity_price?: number
				per_quantity_given_price?: number
				per_quantity_booked_price?: number
			}
		>
	}>
}

export type TValidTransportServiceDatewisePricesFieldValue = Omit<
	Required<
		TTransportServiceDatewisePricesFieldValue,
		"transport_service" | "transport_service_location"
	>,
	"cabs"
> & {
	cabs: Array<
		Required<
			Required<
				TTransportServiceDatewisePricesFieldValue,
				"cabs"
			>["cabs"][number],
			"no_of_cabs" | "cab_type"
		>
	>
}

export function TransportServiceDatewisePricesInputField({
	tripDestinations,
	noOfAdults,
	children,
	dates,
	name,
	selectedCabTypes,
	addPricesAtOnce = false,
	onRemove,
	onDuplicate,
	showBookedPrices,
}: {
	tripDestinations: Array<TTripDestination>
	noOfAdults: number
	children: TChildrenArray
	dates: Array<Date>
	name: string
	selectedCabTypes?: TUsedCabTypes
	addPricesAtOnce?: boolean
	onRemove?: () => void
	onDuplicate?: (data: TTransportServiceDatewisePricesFieldValue) => void
	showBookedPrices?: boolean
}) {
	const xhr = useXHR()
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const form = useForm()
	const readOnlyCabType = Boolean(selectedCabTypes?.length)
	return (
		<>
			<Inline gap="4" borderBottomWidth="1">
				<Grid gap="0" flex="1">
					<Col
						md={12}
						lg={
							addPricesAtOnce && readOnlyCabType
								? 12
								: addPricesAtOnce || readOnlyCabType
									? 7
									: 5
						}
					>
						<Box
							paddingRight={{
								xs: "0",
								md: "4",
							}}
						>
							<GetFieldValue<
								TTransportServiceDatewisePricesFieldValue["transport_service"]
							>
								name={`${name}.transport_service`}
							>
								{({ value: service }) => (
									<Component
										initialState={
											service?.locations?.services
												? service.locations.services.map(transformServiceOption)
												: []
										}
										onMount={({ state, setState }) => {
											if (service && !state?.length) {
												// Fetch the default for location to get all the
												// services to enable selection options
												xhr
													.get(
														`/transport-service-locations/${service.location_id}`
													)
													.then((resp) => resp.data.data)
													.then((location) => {
														// set the options
														setState(
															location.services?.map(transformServiceOption)
														)
													})
											}
										}}
									>
										{({ state: serviceOptions, setState }) => (
											<Grid gap="4" paddingBottom="4" maxWidth="2xl">
												<Col
													xs={12}
													sm={6}
													lg={addPricesAtOnce || readOnlyCabType ? 6 : 12}
												>
													<SelectField
														select={SelectTransportServiceLocations}
														name={`${name}.transport_service_location`}
														creatable
														tripDestinations={tripDestinations}
														label="Service Locations"
														multiple={false}
														onChange={(value) => {
															form.batch(() => {
																form.change(
																	`${name}.transport_service_location` as never,
																	value as never
																)
																if (value?.services?.length) {
																	const options = value.services.map(
																		transformServiceOption
																	)
																	setState(options)
																	form.change(
																		`${name}.transport_service` as never,
																		options[0] as never
																	)
																} else {
																	form.change(
																		`${name}.transport_service` as never,
																		undefined as never
																	)
																}
															})
														}}
													/>
												</Col>
												<Col>
													<SelectField
														select={Select}
														name={`${name}.transport_service`}
														label="Service Type"
														multiple={false}
														options={serviceOptions}
														labelKey="label"
														fullWidth
														help={
															service?.deleted_at ? (
																<Alert status="warning" inline>
																	Service disabled. Please select a different
																	one.
																</Alert>
															) : service?.start_time || service?.duration ? (
																<Text>
																	{joinAttributes(
																		service.start_time_formatted ? (
																			<>
																				Starts: {service.start_time_formatted}
																			</>
																		) : null,
																		service.duration_string ? (
																			<>
																				Duration: {service.duration_string}
																				{service.end_time_formatted
																					? ` (Ends: ${service.end_time_formatted})`
																					: null}
																			</>
																		) : null
																	)}
																</Text>
															) : null
														}
													/>
												</Col>
											</Grid>
										)}
									</Component>
								)}
							</GetFieldValue>
						</Box>
					</Col>
					{addPricesAtOnce && readOnlyCabType ? null : (
						<Col md={12} lg={addPricesAtOnce || readOnlyCabType ? 5 : 7}>
							<Box paddingBottom="4">
								<CabsField
									dates={dates}
									name={name}
									defaultCurrency={currency}
									noOfAdults={noOfAdults}
									children={children}
									selectedCabTypes={selectedCabTypes}
									showPrices={!addPricesAtOnce}
									tripDestinations={tripDestinations}
									showBookedPrices={showBookedPrices}
								/>
							</Box>
						</Col>
					)}
				</Grid>
				{onRemove || onDuplicate ? (
					<Box paddingTop="6" position="relative">
						<Stack gap="4">
							{onRemove ? (
								<Box>
									<Button onClick={() => onRemove()} size="sm">
										<Icons.Cancel />
									</Button>
								</Box>
							) : null}
							{onDuplicate ? (
								<GetFieldValue<TTransportServiceDatewisePricesFieldValue>
									name={name}
								>
									{({ value }) =>
										value && value.transport_service ? (
											<Box>
												<Button onClick={() => onDuplicate(value)} size="sm">
													<Icons.Duplicate />
												</Button>
											</Box>
										) : null
									}
								</GetFieldValue>
							) : null}
						</Stack>
					</Box>
				) : null}
			</Inline>
			<SetupCabsConfigurationForPax
				name={name}
				children={children}
				noOfAdults={noOfAdults}
				selectedCabTypes={selectedCabTypes}
			/>
		</>
	)
}

function CabsField({
	name: transportServiceFieldName,
	defaultCurrency,
	showPrices,
	selectedCabTypes,
	tripDestinations,
	dates,
	showBookedPrices,
}: {
	name: string
	defaultCurrency: string
	noOfAdults: number
	children: TChildrenArray
	showPrices: boolean
	selectedCabTypes?: Array<{
		cab_type: ICabType
		no_of_cabs: number
	}>
	tripDestinations: Array<TTripDestination>
	dates: Array<Date>
	showBookedPrices?: boolean
}) {
	const { value: transportService } = useFieldValue<
		TTransportServiceDatewisePricesFieldValue["transport_service"]
	>(`${transportServiceFieldName}.transport_service`)
	const [refreshCounter, setRefreshCounter] = useState<number>(0)
	const readOnlyCabType = Boolean(selectedCabTypes?.length)
	const { value: existingComments } = useFieldValue<
		TTransportServiceDatewisePricesFieldValue["comments"]
	>(`${transportServiceFieldName}.comments`)
	const [commentsVisible, showAddComments] = useState<boolean>(
		Boolean(existingComments?.length)
	)
	const form = useForm()
	return (
		<Stack gap="1">
			{showPrices ? (
				<Inline gap="4" justifyContent="between">
					<Text fontWeight="semibold">
						Transportation and Prices
						{dates?.length === 1 ? ` - ${formatDate(dates[0], "D MMM")}` : null}
					</Text>
					{dates?.length ? (
						<Inline gap="4" alignItems="center">
							{!commentsVisible ? (
								<Button size="sm" inline onClick={() => showAddComments(true)}>
									<Icons.Annotation /> Comments
								</Button>
							) : null}
							<GetFieldValue<TTransportServiceDatewisePricesFieldValue["cabs"]>
								name={`${transportServiceFieldName}.cabs`}
							>
								{({ value }) => {
									const fetchingPrices = (value || []).some(
										(c) => c.fetching_prices
									)
									return (
										<Button
											inline
											size="sm"
											title="Refresh Prices"
											onClick={() =>
												setRefreshCounter((counter) => counter + 1)
											}
										>
											<Icons.Refresh
												spin={fetchingPrices}
												opacity={fetchingPrices ? "50" : undefined}
											/>
										</Button>
									)
								}}
							</GetFieldValue>
						</Inline>
					) : null}
				</Inline>
			) : null}
			<FieldArray<
				Required<TTransportServiceDatewisePricesFieldValue>["cabs"][number]
			>
				name={`${transportServiceFieldName}.cabs`}
			>
				{({ fields }) => (
					<Stack gap="2">
						<Table
							headers={([] as Array<string>)
								.concat(
									readOnlyCabType
										? ["Transportation"]
										: [showPrices ? "Type" : "Transportation", "Qty."]
								)
								.concat(
									showPrices
										? ([] as Array<string>)
												.concat((dates?.length || 0) > 1 ? ["Date"] : [])
												.concat(["Rate", showBookedPrices ? "Booked" : "Given"])
										: []
								)}
							bordered
						>
							{fields.map((name, cab_row_index) => (
								<tbody key={getFieldArrayItemKey(form, name)}>
									{showPrices ? (
										<>
											<RatesForCab
												transportService={transportService}
												cabFieldName={name}
												dates={dates}
												key={refreshCounter}
											/>
											<ResetEditedGivenPriceFieldOnCabChange
												dates={dates}
												transportService={transportService}
												defaultCurrency={defaultCurrency}
												name={name}
											/>
											{!dates?.length ? null : (
												<FieldArray name={`${name}.date_wise_prices`}>
													{({ fields: dateWisePricesFields }) =>
														dateWisePricesFields.map(
															(dateWisePriceFieldName, date_row_index) => {
																return (
																	<tr key={dateWisePriceFieldName}>
																		{readOnlyCabType ? (
																			<TableDataCell>
																				{date_row_index === 0 ? (
																					<GetFieldValue<ICabType | undefined>
																						name={`${name}.cab_type`}
																					>
																						{({ value: cab_type }) => (
																							<GetFieldValue<
																								ICabType | undefined
																							>
																								name={`${name}.no_of_cabs`}
																							>
																								{({ value: no_of_cabs }) => (
																									<Text>
																										<>
																											{no_of_cabs || 0} -{" "}
																											{cab_type?.name ||
																												"<Required>"}
																										</>
																									</Text>
																								)}
																							</GetFieldValue>
																						)}
																					</GetFieldValue>
																				) : null}
																			</TableDataCell>
																		) : (
																			<>
																				<TableDataCell>
																					{date_row_index === 0 ? (
																						<Inline gap="1" flexWrap="wrap">
																							<Box style={{ width: "180px" }}>
																								<SelectField
																									select={SelectCabTypes}
																									name={`${name}.cab_type`}
																									minWidth="100px"
																									size="sm"
																									clearable={false}
																									tripDestinations={
																										tripDestinations
																									}
																									fetchOnMount
																									creatable
																								/>
																							</Box>
																							{(fields.length || 0) > 1 ? (
																								<Box>
																									<Button
																										onClick={() =>
																											fields.remove(
																												cab_row_index
																											)
																										}
																										level="tertiary"
																										size="sm"
																										disabled={
																											(fields.length || 0) <= 1
																										}
																									>
																										<Icons.Cancel />
																									</Button>
																								</Box>
																							) : null}
																						</Inline>
																					) : null}
																				</TableDataCell>
																				<TableDataCell>
																					{date_row_index === 0 ? (
																						<TextInputField
																							size="sm"
																							name={`${name}.no_of_cabs`}
																							style={{
																								width: "50px",
																							}}
																							type="number"
																							placeholder="1"
																							min={1}
																						/>
																					) : null}
																				</TableDataCell>
																			</>
																		)}
																		<GetFieldValue<string>
																			name={`${dateWisePriceFieldName}.currency`}
																		>
																			{({ value: currency }) => {
																				return (
																					<>
																						{dates?.length > 1 ? (
																							<TableDataCell>
																								{dates[date_row_index] ? (
																									<Text>
																										{formatDate(
																											dates[date_row_index],
																											"D MMM"
																										)}
																									</Text>
																								) : null}
																							</TableDataCell>
																						) : null}
																						<TableDataCell>
																							<GetFieldValue<number | undefined>
																								name={`${dateWisePriceFieldName}.per_quantity_price`}
																							>
																								{({ value }) =>
																									value ? (
																										<Money
																											currency={currency}
																											amount={value}
																											showCurrency
																										/>
																									) : (
																										<Box>
																											<Box
																												as="span"
																												color="muted"
																												fontSize="xs"
																												verticalAlign="super"
																											>
																												<InlineSelectTenantCurrencyInputField
																													name={`${dateWisePriceFieldName}.currency`}
																													noCaret
																													defaultValue={
																														defaultCurrency
																													}
																												/>
																											</Box>{" "}
																											<Text
																												color="warning"
																												title="Not Available"
																												as="span"
																											>
																												N/A
																											</Text>
																										</Box>
																									)
																								}
																							</GetFieldValue>
																						</TableDataCell>
																						<TableDataCell>
																							<GetFieldValue<number | undefined>
																								name={`${name}.no_of_cabs`}
																							>
																								{({ value }) =>
																									!showBookedPrices ? (
																										<DatewiseGivenPriceForCab
																											name={`${dateWisePriceFieldName}`}
																											noOfCabs={value}
																										/>
																									) : (
																										<DatewiseBookedPriceForCab
																											name={`${dateWisePriceFieldName}`}
																											noOfCabs={value}
																										/>
																									)
																								}
																							</GetFieldValue>
																						</TableDataCell>
																					</>
																				)
																			}}
																		</GetFieldValue>
																	</tr>
																)
															}
														)
													}
												</FieldArray>
											)}
										</>
									) : readOnlyCabType ? (
										<tr>
											<TableDataCell>
												<GetFieldValue<ICabType | undefined>
													name={`${name}.cab_type`}
												>
													{({ value: cab_type }) => (
														<GetFieldValue<ICabType | undefined>
															name={`${name}.no_of_cabs`}
														>
															{({ value: no_of_cabs }) => (
																<Text>
																	<>
																		{no_of_cabs || 0} -{" "}
																		{cab_type?.name || "<Required>"}
																	</>
																</Text>
															)}
														</GetFieldValue>
													)}
												</GetFieldValue>
											</TableDataCell>
										</tr>
									) : (
										<tr key={name}>
											<TableDataCell>
												<Box style={{ width: "180px" }}>
													<SelectField
														select={SelectCabTypes}
														name={`${name}.cab_type`}
														minWidth="100px"
														size="sm"
														clearable={false}
														tripDestinations={tripDestinations}
														fetchOnMount
														creatable
													/>
												</Box>
											</TableDataCell>
											<TableDataCell>
												<TextInputField
													size="sm"
													name={`${name}.no_of_cabs`}
													style={{
														width: "70px",
													}}
													type="number"
													placeholder="1"
													min={1}
												/>
											</TableDataCell>
											<TableDataCell>
												<Box opacity={(fields.length || 0) <= 1 ? "0" : "100"}>
													<Button
														onClick={() => fields.remove(cab_row_index)}
														inline
														disabled={(fields.length || 0) <= 1}
													>
														<Icons.Cancel />
													</Button>
												</Box>
											</TableDataCell>
										</tr>
									)}
								</tbody>
							))}
						</Table>
						{!selectedCabTypes?.length && dates?.length ? (
							<Box>
								<Button
									onClick={() =>
										fields.push(
											addKeyToFieldArrayItem({
												no_of_cabs: 1,
												cab_type: undefined,
											})
										)
									}
									inline
									level="tertiary"
								>
									<Icons.Plus /> Add More
								</Button>
							</Box>
						) : null}
					</Stack>
				)}
			</FieldArray>
			{commentsVisible ? (
				<TextInputField
					name={`${transportServiceFieldName}.comments`}
					label="Comments"
					type="text"
					size="sm"
					placeholder="Any comments regarding prices..."
				/>
			) : null}
		</Stack>
	)
}

function SetupCabsConfigurationForPax({
	name,
	selectedCabTypes,
}: // noOfAdults,
// children,
{
	name: string
	noOfAdults: number
	children: TChildrenArray
} & Pick<React.ComponentProps<typeof CabsField>, "selectedCabTypes">) {
	const { value } =
		useFieldValue<TTransportServiceDatewisePricesFieldValue>(name)
	const { cabs } = value || { cabs: [] }
	const lastUpdateCabsRef = useRef<
		Array<{ no_of_cabs?: number; cab_type?: ICabType }> | undefined
	>(cabs)
	const hasCabsAttached = Boolean(cabs?.length)
	const form = useForm()

	// create a ticket configuration if not already exists
	useEffect(() => {
		if (!hasCabsAttached && !selectedCabTypes?.length) {
			// add tourist configurations
			// TODO: Auto generate a sensible configuration of cabs for given pax
			setTimeout(() => {
				const cabs: TTransportServiceDatewisePricesFieldValue["cabs"] = [
					addKeyToFieldArrayItem({
						cab_type: undefined,
						no_of_cabs: 1,
					}),
				]
				form.change(`${name}.cabs`, cabs)
			})
		}
	}, [form, hasCabsAttached, name, selectedCabTypes])
	useEffect(() => {
		if (selectedCabTypes?.length) {
			const lastUpdatedCabs = lastUpdateCabsRef.current
			const cabsAreDifferentFromSelectedCabTypes =
				selectedCabTypes?.length !== lastUpdatedCabs?.length ||
				(selectedCabTypes || []).some(({ cab_type, no_of_cabs }, i) => {
					return (
						!lastUpdatedCabs[i] ||
						!lastUpdatedCabs[i].cab_type ||
						!lastUpdatedCabs[i].no_of_cabs ||
						lastUpdatedCabs[i].cab_type?.id !== cab_type.id ||
						Number(lastUpdatedCabs[i].no_of_cabs || 0) !==
							Number(no_of_cabs || 1)
					)
				})
			if (cabsAreDifferentFromSelectedCabTypes) {
				const cabs: TTransportServiceDatewisePricesFieldValue["cabs"] =
					selectedCabTypes.map((c) => addKeyToFieldArrayItem(c))
				// set the selected cab  type
				form.change(`${name}.cabs`, cabs)
				lastUpdateCabsRef.current = selectedCabTypes
			}
		} else {
			lastUpdateCabsRef.current = selectedCabTypes
		}
	}, [form, name, selectedCabTypes])
	return null
}

function RatesForCab({
	transportService,
	cabFieldName,
	dates,
}: {
	transportService?: ITransportService
	cabFieldName: string
	dates?: Array<Date>
}) {
	const { value: no_of_cabs } = useFieldValue<
		Required<TTransportServiceDatewisePricesFieldValue>["cabs"][number]["no_of_cabs"]
	>(`${cabFieldName}.no_of_cabs`)

	const { value: cabType } = useFieldValue<
		Required<TTransportServiceDatewisePricesFieldValue>["cabs"][number]["cab_type"]
	>(`${cabFieldName}.cab_type`)

	const form = useForm()
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { set: setTimer, clear: clearTimer } = useTimeout()
	useEffect(() => {
		// abort any pending requests
		abortController.current = new AbortController()
		if (!transportService || !cabType || !no_of_cabs || !dates?.length) {
			abortController.current?.abort()
			clearTimer()
			return () => undefined
		}
		setTimer(() => {
			// fetch the prices and update
			Promise.resolve()
				.then(async () => {
					form.change(`${cabFieldName}.fetching_prices`, 1)
					const prices = await Promise.all(
						dates.map((date) =>
							xhr
								.get<{
									data: {
										price: number | null
										per_quantity_price: number | null
										currency: string | null
									}
								}>(`/cab-prices/calculate`, {
									params: {
										date: dateToUTCString(date),
										date_local: formatDate(date, "YYYY-MM-DD"),
										transport_service_id: transportService.id,
										cab_type: cabType.id,
										no_of_cabs: no_of_cabs,
									},
									signal: abortController.current?.signal,
								})
								.then((resp) => resp.data.data)
						)
					)
					abortController.current = undefined
					form.batch(() => {
						dates.forEach((date, index) => {
							const per_quantity_price = prices[index].per_quantity_price
							const total_price = prices[index].price
							const currency = prices[index].currency

							form.change(
								`${cabFieldName}.date_wise_prices[${index}].date`,
								date
							)

							// if we have a currency, then only we update the currency/rates
							if (currency) {
								form.change(
									`${cabFieldName}.date_wise_prices[${index}].currency`,
									currency
								)
								form.change(
									`${cabFieldName}.date_wise_prices[${index}].per_quantity_price`,
									per_quantity_price !== undefined &&
										per_quantity_price !== null
										? formatMoneyByDecimal(
												moneyParseByDecimal(per_quantity_price, currency)
											)
										: undefined
								)
								form.change(
									`${cabFieldName}.date_wise_prices[${index}].price`,
									total_price !== undefined && total_price !== null
										? formatMoneyByDecimal(
												moneyParseByDecimal(total_price, currency)
											)
										: undefined
								)
							} else {
								// otherwise, we reset the rates and currency remains the same
								form.change(
									`${cabFieldName}.date_wise_prices[${index}].per_quantity_price`,
									undefined
								)
								form.change(
									`${cabFieldName}.date_wise_prices[${index}].price`,
									undefined
								)
							}
						})
					})
				})
				.then(() => {
					form.change(`${cabFieldName}.fetching_prices`, 0)
				})
				.catch((e) => {
					if (!isAbortError(e)) {
						form.change(`${cabFieldName}.fetching_prices`, 0)
						throw e
					}
				})
		}, 300)
		return () => {
			// Cancel any pending fetch
			abortController.current?.abort()
			clearTimer()
		}
	}, [
		transportService,
		cabType,
		no_of_cabs,
		form,
		dates,
		cabFieldName,
		xhr,
		setTimer,
		clearTimer,
	])
	return null
}

function DatewiseGivenPriceForCab({
	name,
	noOfCabs,
}: {
	name: string
	noOfCabs?: number
}) {
	const form = useForm()
	const { value: price } = useFieldValue<number | undefined>(`${name}.price`)
	const { value: per_quantity_price } = useFieldValue<number | undefined>(
		`${name}.per_quantity_price`
	)
	const { value: currency } = useFieldValue<string>(`${name}.currency`)
	const { value: per_quantity_given_price } = useFieldValue<number | undefined>(
		`${name}.per_quantity_given_price`
	)
	const { value: edited_given_price } = useFieldValue<boolean | undefined>(
		`${name}.edited_given_price`
	)
	// reset the given prices if given prices have not been manually updated
	useEffect(() => {
		if (!edited_given_price) {
			setTimeout(() => {
				form.batch(() => {
					form.change(`${name}.per_quantity_given_price`, per_quantity_price)
					form.change(`${name}.given_price`, price || 0)
				})
			})
		}
	}, [name, price, per_quantity_price, edited_given_price, form])

	// sync the given price when per_quantity_given_price or noOfCabs changes
	useEffect(() => {
		if (edited_given_price) {
			const given_price =
				Number(noOfCabs || 0) * Number(per_quantity_given_price || 0)
			setTimeout(() => {
				form.batch(() => {
					form.change(`${name}.given_price`, given_price)
				})
			}, 0)
		}
	}, [edited_given_price, noOfCabs, per_quantity_given_price, form, name])

	return (
		<TextInputField
			size="sm"
			name={`${name}.per_quantity_given_price`}
			type="number"
			placeholder="1"
			style={{ maxWidth: "100px" }}
			readOnly={!noOfCabs}
			help={
				<GetFieldValue<number | undefined> name={`${name}.given_price`}>
					{({ value }) =>
						value && Number(noOfCabs || 0) > 1 ? (
							<Text>
								x {noOfCabs} = <Money currency={currency} amount={value} />
							</Text>
						) : null
					}
				</GetFieldValue>
			}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				const value = e.currentTarget.value
				form.batch(() => {
					form.change(`${name}.edited_given_price` as never, true as never)
					form.change(
						`${name}.per_quantity_given_price` as never,
						value as never
					)
				})
			}}
		/>
	)
}

function DatewiseBookedPriceForCab({
	name,
	noOfCabs,
}: {
	name: string
	noOfCabs?: number
}) {
	const form = useForm()
	const { value: price } = useFieldValue<number | undefined>(`${name}.price`)
	const { value: per_quantity_price } = useFieldValue<number | undefined>(
		`${name}.per_quantity_price`
	)
	const { value: currency } = useFieldValue<string>(`${name}.currency`)
	const { value: per_quantity_booked_price } = useFieldValue<
		number | undefined
	>(`${name}.per_quantity_booked_price`)
	const { value: edited_booked_price } = useFieldValue<boolean | undefined>(
		`${name}.edited_booked_price`
	)
	// reset the given prices if given prices have not been manually updated
	useEffect(() => {
		if (!edited_booked_price) {
			setTimeout(() => {
				form.batch(() => {
					form.change(`${name}.per_quantity_booked_price`, per_quantity_price)
					form.change(`${name}.booked_price`, price || 0)
				})
			})
		}
	}, [name, price, per_quantity_price, edited_booked_price, form])

	// sync the given price when per_quantity_given_price or noOfCabs changes
	useEffect(() => {
		if (edited_booked_price) {
			const given_price =
				Number(noOfCabs || 0) * Number(per_quantity_booked_price || 0)
			setTimeout(() => {
				form.batch(() => {
					form.change(`${name}.booked_price`, given_price)
				})
			}, 0)
		}
	}, [edited_booked_price, noOfCabs, per_quantity_booked_price, form, name])

	return (
		<TextInputField
			size="sm"
			name={`${name}.per_quantity_booked_price`}
			type="number"
			placeholder="1"
			style={{ maxWidth: "100px" }}
			readOnly={!noOfCabs}
			help={
				<GetFieldValue<number | undefined> name={`${name}.booked_price`}>
					{({ value }) =>
						value && Number(noOfCabs || 0) > 1 ? (
							<Text>
								x {noOfCabs} = <Money currency={currency} amount={value} />
							</Text>
						) : null
					}
				</GetFieldValue>
			}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				const value = e.currentTarget.value
				form.batch(() => {
					form.change(`${name}.edited_booked_price` as never, true as never)
					form.change(
						`${name}.per_quantity_booked_price` as never,
						value as never
					)
				})
			}}
		/>
	)
}

function ResetEditedGivenPriceFieldOnCabChange({
	name,
	transportService,
	dates,
	defaultCurrency,
}: {
	name: string
	transportService?: ITransportService
	dates?: Array<Date>
	defaultCurrency: string
}) {
	const { value } =
		useFieldValue<
			Required<TTransportServiceDatewisePricesFieldValue>["cabs"][number]
		>(name)
	const previousValues = useRef({
		cab_type: value.cab_type,
		transportService,
		// on-mount, if we have dates but not date-wise-prices,
		// we set it to empty to ensure that we generate the date_wise_prices
		dates: dates?.length && !value.date_wise_prices?.length ? [] : dates,
	})
	const form = useForm()
	useEffect(() => {
		if (
			previousValues.current.cab_type !== value.cab_type ||
			previousValues.current.transportService !== transportService ||
			previousValues.current.dates?.length !== dates?.length
		) {
			const date_wise_prices = dates?.length
				? dates.map((date) => ({
						...(value.date_wise_prices?.find((data) =>
							isSame(data.date, date, "day")
						) || {
							currency: defaultCurrency,
							given_price: 0,
							per_quantity_given_price: 0,
							booked_price: 0,
							per_quantity_booked_price: 0,
							price: 0,
							per_quantity_price: 0,
						}),
						date,
						edited_given_price: false,
						edited_booked_price: false,
					}))
				: []
			setTimeout(() => {
				form.change(`${name}.date_wise_prices`, date_wise_prices)
			}, 0)
			previousValues.current.cab_type = value.cab_type
			previousValues.current.transportService = transportService
			previousValues.current.dates = dates
		}
	}, [defaultCurrency, value, form, name, transportService, dates])
	return null
}

function transformServiceOption(service: ITransportService) {
	return {
		...service,
		label: service.service,
	}
}

export function AddPricesAtOnceSwitchInputField({
	name,
	onSetPriceCalculations,
	onRemoveServiceWisePrice,
}: {
	name: string
	onSetPriceCalculations: (calculations: [] | undefined) => void
	onRemoveServiceWisePrice: () => void
}) {
	const form = useForm()
	return (
		<SwitchInputField
			name={name}
			label="Add Prices at Once"
			onChange={(once) => {
				form.batch(() => {
					form.change(name as "add_cab_prices_at_once", once as never)
					onSetPriceCalculations(once ? [] : undefined)
					if (once) {
						// remove the prices from cabs
						onRemoveServiceWisePrice()
					}
				})
			}}
		/>
	)
}
